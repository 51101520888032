import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import $ from "jquery";
import React from 'react';
import { FormattedMessage, injectIntl, intlShape } from "react-intl";
import Moment from "react-moment";
import * as constants from "../../../util/constants";
import Alert from "../../common/Alert";
import Breadcrumb from "../../common/Breadcrumb";
import ButtonBack from "../../common/ButtonBack";
import ButtonSave from "../../common/ButtonSave";
import FieldDate from "../../common/FieldDate";
import FieldPaymentProvider from "../../common/FieldPaymentProvider";
import FieldSegmentCode from "../../common/FieldSegmentCode";
import FieldSelect from "../../common/FieldSelect";
import FieldTextarea from "../../common/FieldTextarea";
import FieldUserType from "../../common/FieldUserType";
import Modal from "../../common/Modal";
import Propertii from "../../common/Propertii";
import Table from "../../common/Table";
import FieldCompanies from "../../common/FieldCompanies";
import lodash from "lodash";

class Broadcasts extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {
            broadcastMessage: {},
            broadcastMessageList: {
                page: '',
                recordsPerPage: '',
                totalPages: '',
                totalRecordCount: '',
                records: [
                    {}
                ]
            },
            broadcastMessageQuery: {
                orderBy: 'DESC',
                orderByFields: ['createDate'],
                conditionList: []
            },
            validationList: [],

            selectedCompaniesList:[],
            companyList: [],
        };

        this.searchBroadcastMessages = this.searchBroadcastMessages.bind(this);
        this.viewBroadcastMessage = this.viewBroadcastMessage.bind(this);
        this.initBroadcastMessage = this.initBroadcastMessage.bind(this);
        this.saveBroadcastMessage = this.saveBroadcastMessage.bind(this);
        this.deleteBroadcastMessage = this.deleteBroadcastMessage.bind(this);
        this.handleChangeAccessedCompanyList = this.handleChangeAccessedCompanyList.bind(this);
        this.searchCompanies = this.searchCompanies.bind(this);
    }

    /**
     * Load the list of fee profiles on mount.
     */
    componentDidMount() {

        this.searchBroadcastMessages(1, 25, this.state.broadcastMessageQuery);
        this.searchCompanies();
    }

    /**
     * Handle selecting a broadcast message record by bringing up the modal with broadcast message values pre-filled.
     *
     * @param broadcastMessageId - The ID of the broadcast message selected.
     */
    viewBroadcastMessage(broadcastMessageId) {

        let initialAccessedCompanyList = [];

        axios.get(`${constants.REACT_APP_HOST_API_URL}/broadcast_message/${broadcastMessageId}`, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            let broadcastMessage = response.data;
            if(broadcastMessage.login){

                broadcastMessage.userType = 'LOGIN_SCREEN';
            }

            // List of company accessed ID's
            let accessCompanies = [...new Set(broadcastMessage.accessCompanies)];

            if(accessCompanies.length > 0){

                accessCompanies.forEach((accessedCompanyId)=>{
                    this.state.companyList.forEach((company)=>{
                        if(company.id === accessedCompanyId){
                            initialAccessedCompanyList.push({
                                value:accessedCompanyId,
                                label:company.name
                            });
                        }
                    });
                });

            }

            this.setState(prevState => ({
                ...prevState,
                broadcastMessage: broadcastMessage,
                selectedCompaniesList: lodash.uniqWith(initialAccessedCompanyList, lodash.isEqual),
            }));

            $('#broadcast-message').modal('show');

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Initialize a new instance of a broadcast message object when the user clicks the appropriate button.
     */
    initBroadcastMessage() {

        // Reset selected company field
        if(this.state.selectedCompaniesList.length > 0){
            this.setState(prevState => ({
                ...prevState,
                selectedCompaniesList: []
            }));
        }

        axios.get(`${constants.REACT_APP_HOST_API_URL}/broadcast_message/new`).then(response => {
            this.setState(prevState => ({
                ...prevState,
                broadcastMessage: response.data
            }));
        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Delete the selected broadcast message.
     */
    deleteBroadcastMessage() {

        axios.delete(`${constants.REACT_APP_HOST_API_URL}/broadcast_message/${this.state.broadcastMessage.id}/delete`, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState({
                validationList: [{
                    fields: {},
                    alert: {
                        type: 'primary',
                        code: 'admin.operations.broadcasts.deleted'
                    }
                }],
            });

            this.searchBroadcastMessages(this.state.broadcastMessageList.page, this.state.broadcastMessageList.recordsPerPage, this.state.broadcastMessageQuery);

            $('#broadcast-message').modal('hide');

        }).catch(error => {
            this.handleValidation(error);
        });

        window.scrollTo(0, 0);
    }


    /**
     * Handle the submission of the form. Use the create date field of the broadcast message in the state to determine
     * if the user is editing or creating a new broadcast message. Upon successful submission of the form, refresh the
     * table and hide the modal.
     *
     * @param event - The event container.
     */
    saveBroadcastMessage(event) {

        event.preventDefault();

        if(this.state.broadcastMessage.userType === 'LOGIN_SCREEN'){

            let broadcastMessage = this.state.broadcastMessage;
            broadcastMessage.userType = '';
            broadcastMessage.login = true;

            this.setState(prevState => ({
                ...prevState,
                broadcastMessage: broadcastMessage
            }));
        }

        if(this.state.broadcastMessage.createDate == null) {
            axios.post(`${constants.REACT_APP_HOST_API_URL}/create`, this.state.broadcastMessage, {
                headers: this.generateRequestHeaders()
            }).then(response => {

                this.setState({
                    validationList: [{
                        fields: {},
                        alert: {
                            type: 'primary',
                            code: 'admin.operations.broadcasts.created'
                        }
                    }],
                });

                this.searchBroadcastMessages(this.state.broadcastMessageList.page, this.state.broadcastMessageList.recordsPerPage, this.state.broadcastMessageQuery);

                $('#broadcast-message').modal('hide');

            }).catch(error => {
                this.handleValidation(error);
            });
        } else {
            axios.put(`${constants.REACT_APP_HOST_API_URL}/update`, this.state.broadcastMessage, {
                headers: this.generateRequestHeaders()
            }).then(response => {

                this.setState({
                    validationList: [{
                        fields: {},
                        alert: {
                            type: 'primary',
                            code: 'saved',
                            message: 'Changes have been saved'
                        }
                    }],
                });

                this.searchBroadcastMessages(this.state.broadcastMessageList.page, this.state.broadcastMessageList.recordsPerPage, this.state.broadcastMessageQuery);

                $('#broadcast-message').modal('hide');

            }).catch(error => {
                this.handleValidation(error);
            });
        }

        window.scrollTo(0, 0);
    }

    /**
     * Update the data table of broadcast messages.
     *
     * @param page - The page to display.
     * @param recordsPerPage - The amount of records to display on each page.
     * @param query - The search query.
     */
    searchBroadcastMessages(page, recordsPerPage, query) {

        axios.post(`${constants.REACT_APP_HOST_API_URL}/broadcast_message/search?recordsPerPage=${recordsPerPage}&page=${page}`, {
            orderBy: query.orderBy,
            orderByFields: query.orderByFields,
            conditionList: query.conditionList
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                broadcastMessageList: response.data,
                broadcastMessageQuery: {
                    orderBy: query.orderBy,
                    orderByFields: query.orderByFields,
                    conditionList: query.conditionList
                }
            }));
        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Handle change to the company field of api account.
     * @param selectedOptions -
     */
    handleChangeAccessedCompanyList(selectedOptions){
        this.setState(prevState=>({
            ...prevState,
            spinner: true
        }));

        let selectedCompaniesList = [];
        let accessCompaniesList = [];

        if(selectedOptions == null){
            selectedOptions = []
        }

        if(selectedOptions.length === 0) {
            selectedCompaniesList = [];
            accessCompaniesList = [];
        }

        if(selectedOptions.length > 0) {
            selectedOptions.forEach((selectedCompany) => {

                let companyList = this.state.companyList;

                companyList.forEach((company)=>{

                    if(company.id === selectedCompany.value && !lodash.includes(accessCompaniesList, selectedCompany.value)){
                        accessCompaniesList.push(company.id);
                        selectedCompaniesList.push({
                            value:company.id,
                            label:company.name
                        });
                    }
                })
            });
        }

        // Use new Set() constructor to remove duplicates since it's an array of strings
        // lodahsh.uniqWith is used to filter duplicates from an array of objects, Set() doesn't work well

        this.setState(prevState => ({
            ...prevState,
            spinner:false,
            broadcastMessage:{
                ...prevState.broadcastMessage,
                accessCompanies:[...new Set(accessCompaniesList)]
            },
            selectedCompaniesList:lodash.uniqWith(selectedCompaniesList, lodash.isEqual)
        }));
    }

    /**
     * Populates the compnay list with all companies in the Db
     *
     * @param page - The page to display.
     * @param recordsPerPage - The amount of records to display on each page.
     * @param query - The search query.
     */
    searchCompanies() {
        let companyQuery = {
            orderBy: 'ASC',
            orderByFields: ['name'],
            conditionList: [
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'landlordId',
                    operator: 'EQUALS',
                    fieldValue: null
                }
            ]
        }
        axios.post(`${constants.REACT_APP_HOST_API_URL}/company/search?recordsPerPage=9999&page=1`, companyQuery, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                companyList: response.data.records
            }));
        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Render the component.
     *
     * @returns {*} - The default fee profile list interface.
     */
    render() {

        const {formatMessage} = this.props.intl;

        return(
            <div className="content-block">

                <div className="container">

                    <Breadcrumb parentPath="/admin/system" parentPage="System" childPage="Broadcasts" />

                    <Alert validationList={this.state.validationList} validationType="primary" />

                    <div className="card">
                        <div className="card-header">
                            <div className="row align-items-center">
                                <div className="col">
                                    Broadcasts
                                </div>
                                <div className="col text-right">
                                    <div data-toggle="modal" data-target="#broadcast-message" className="btn btn-primary btn-sm" onClick={() => this.initBroadcastMessage()}>
                                        <FontAwesomeIcon icon={['fas', 'plus']} className="fa-fw" /> Create Broadcast
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Table columns={{createDate: 'Create Date', startDate: 'Date Range', paymentProviderId: 'Processor', segmentCode: 'Segment', userType: 'User Type', severity: 'Severity'}}
                               columnWidths={['17%', '17%', '17%', '17%', '15%', '15%']}
                               headerClass="c-pointer"
                               data={this.state.broadcastMessageList}
                               query={this.state.broadcastMessageQuery}
                               sortEnabled={true}
                               recordsEnabled={true}
                               paginationEnabled={true}
                               updateFunction={this.searchBroadcastMessages}>
                            <tbody>
                            {this.state.broadcastMessageList.records.map((data, key) => {
                                return(
                                    <tr key={key} onClick={() => this.viewBroadcastMessage(data.id)} className="c-pointer">
                                        <td>
                                            <div className="">
                                                <Moment format="MMM DD, YYYY">
                                                    {data.createDate}
                                                </Moment>
                                            </div>
                                        </td>
                                        <td>
                                            {(data.startDate && data.endDate) &&
                                            <div className="">
                                                <Moment format="MMM DD, YYYY" tz="UTC">{data.startDate}</Moment> to <Moment format="MMM DD, YYYY" tz="UTC">{data.endDate}</Moment>
                                            </div>
                                            }
                                            {(data.startDate && !data.endDate) &&
                                            <div className="">
                                                Begins <Moment format="MMM DD, YYYY">{data.startDate}</Moment>
                                            </div>
                                            }
                                            {(!data.startDate && data.endDate) &&
                                            <div className="">
                                                Ends <Moment format="MMM DD, YYYY" tz="UTC">{data.endDate}</Moment>
                                            </div>
                                            }
                                            {(!data.startDate && !data.endDate) &&
                                            <div className="">
                                                Endless
                                            </div>
                                            }
                                        </td>
                                        <td>
                                            <div className="">
                                                {data.paymentProviderId &&
                                                <FormattedMessage id={"enum.paymentProviderId." + data.paymentProviderId}/>
                                                }
                                                {!data.paymentProviderId &&
                                                <span className="">All Processors</span>
                                                }
                                            </div>
                                        </td>
                                        <td>
                                            <div className="">
                                                {data.segmentCode &&
                                                <FormattedMessage id={"enum.segmentCode." + data.segmentCode}/>
                                                }
                                                {!data.segmentCode &&
                                                <span className="">All Segments</span>
                                                }
                                            </div>
                                        </td>
                                        <td>
                                            <div className="">
                                                {data.userType && !data.login &&
                                                <FormattedMessage id={"enum.record." + data.userType}/>
                                                }
                                                {data.login &&
                                                    <span className="">Login Screen</span>
                                                }
                                                {!data.userType && !data.login &&
                                                <span className="">All User Types</span>
                                                }
                                            </div>
                                        </td>
                                        <td>
                                            <div className="text-nowrap">
                                                <FontAwesomeIcon icon={['fas', 'circle']} className={`fa-fw small ${formatMessage({id: "enum.broadcastMessage.severity." + data.severity + ".class"})}`}/>
                                                <span className="ml-1"><FormattedMessage id={"enum.broadcastMessage.severity." + data.severity}/></span>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </Table>
                    </div>

                    <div className="modal fade" id="broadcast-message" tabIndex="-1" role="dialog" aria-labelledby="broadcast-message-label" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                            <div className="modal-content shadow">
                                <form onSubmit={this.saveBroadcastMessage}>
                                    <div className="modal-header bg-dark text-white">
                                        <h5 className="modal-title" id="broadcast-message-label">
                                            {this.state.broadcastMessage['createDate'] == null &&
                                            <span className="">
                                            Create Broadcast
                                        </span>
                                            }
                                            {this.state.broadcastMessage['createDate'] != null &&
                                            <span className="">
                                            Edit Broadcast
                                        </span>
                                            }
                                        </h5>
                                        <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                            <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw va-b mr-2" />
                                        </button>
                                    </div>
                                    <div className="modal-body">

                                        <Alert validationList={this.state.validationList} validationType="danger" />

                                        <FieldSelect id="severity" label="Severity" model="broadcastMessage" parent={this} value={this.state.broadcastMessage['severity']}>
                                            <option value="">Select a severity...</option>
                                            <option value="CRITICAL">Critical</option>
                                            <option value="WARNING">Warning</option>
                                            <option value="INFO">Info</option>
                                        </FieldSelect>

                                        <FieldPaymentProvider id="paymentProviderId" label="Payment Provider" model="broadcastMessage" parent={this} value={this.state.broadcastMessage['paymentProviderId']} />

                                        <FieldSegmentCode id="segmentCode" label="Segment Code" model="broadcastMessage" parent={this} value={this.state.broadcastMessage['segmentCode']} />

                                        <FieldUserType id="userType" label="User Type" model="broadcastMessage" parent={this} value={this.state.broadcastMessage['userType']} />

                                        <FieldDate id="startDate" label="Start Date" model="broadcastMessage" parent={this} value={this.state.broadcastMessage['startDate']} selectsStart={true} startDate={this.state.broadcastMessage['startDate']} endDate={this.state.broadcastMessage['startDate']} />
                                        
                                        {/* <FieldDate id="test" label="test" model="broadcastMessage" parent={this} value={this.state.broadcastMessage['startDate']} selectsStart={true} /> */}
                                

                                        <FieldDate id="endDate" label="End Date" model="broadcastMessage" parent={this} value={this.state.broadcastMessage['endDate']} selectsEnd={this} startDate={this.state.broadcastMessage['startDate']} endDate={this.state.broadcastMessage['endDate']} minDate={this.state.broadcastMessage['startDate']} />

                                        {this.state.broadcastMessage['userType'] === 'TYPE_CUSTOMER' &&

                                            <FieldCompanies id="selectedCompaniesList" label="Companies" labelClass="col-form-label-sm align-self-center"
                                                            value={this.state.selectedCompaniesList} parent={this} options={this.state.companyList}
                                                            handleChange={this.handleChangeAccessedCompanyList} help={
                                                <div>
                                                    <div className='text-muted form-text'>
                                                        Note: The Company Selection is initially required for only for Tenant Notifications for toast popup. If possible we should only show the Companies drop-down control when Tenant User Type is selected, otherwise it should be hidden or disabled.
                                                    </div>
                                                </div>
                                            }/>
                                        }

                                        <FieldTextarea id="message" label="Message" model="broadcastMessage" parent={this} value={this.state.broadcastMessage['message']} />

                                    </div>
                                    <div className="modal-footer bg-secondary rounded-bottom d-block">
                                        <div className="row">
                                            <div className="col-4">
                                                <button type="button" className="btn btn-outline-primary btn-lg" onClick={() => $("#broadcast-message").modal("hide")}>Close</button>
                                            </div>
                                            <div className="col-8 text-right">

                                                {this.state.broadcastMessage['createDate'] != null &&
                                                <div className="btn btn-lg btn-primary ml-2" onClick={() => {$("#broadcast-message").modal("hide"); $("#delete-broadcast-message").modal("show");}}>
                                                    Delete
                                                </div>
                                                }

                                                <ButtonSave />

                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                    <Modal id="delete-broadcast-message" theme="danger" iconType="fas" iconName="exclamation-triangle" title="Delete Broadcast"
                           body="Are you sure you would like to delete this broadcast?">
                        <button type="button" className="btn btn-outline-danger btn-lg" data-dismiss="modal" onClick={() => {$("#broadcast-message").modal("show")}}>
                            <FormattedMessage id="button.back" />
                        </button>
                        <button onClick={() => {this.deleteBroadcastMessage()}} className="btn btn-danger btn-lg" data-dismiss="modal">
                            Delete Broadcast
                        </button>
                    </Modal>

                    <div className="row">
                        <div className="col text-right">

                            <ButtonBack path="/admin/system" />

                        </div>
                    </div>

                </div>

            </div>
        )
    };
}

Broadcasts.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(Broadcasts);